var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 grid-margin"},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"card-col col-xl-4 col-lg-4 col-md-4 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 mr-sm-4 icon-lg"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8696"},attrs:{"to":{
                      path: '/customers',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countCustomer.startVal,"endVal":_vm.countCustomer.endVal,"duration":_vm.countCustomer.duration}})],1)],1),_vm._m(0)])])]),_c('div',{staticClass:"card-col col-xl-4 col-lg-4 col-md-4 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 mr-sm-4 icon-lg"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8696"},attrs:{"to":{
                      path: '/drivers',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countDriver.startVal,"endVal":_vm.countDriver.endVal,"duration":_vm.countDriver.duration}})],1)],1),_vm._m(1)])])]),_c('div',{staticClass:"card-col col-xl-4 col-lg-4 col-md-4 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 mr-sm-4 icon-lg"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8696"},attrs:{"to":{
                      path: '/help-and-support',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countHelp.startVal,"endVal":_vm.countHelp.endVal,"duration":_vm.countHelp.duration}})],1)],1),_vm._m(2)])])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 grid-margin"},[_c('div',{staticClass:"card card-statistics"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"card-col col-xl-4 col-lg-4 col-md-4 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 icon-lg"},[_c('i',{staticClass:"mdi mdi-ticket-confirmation"}),_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8696"},attrs:{"to":{
                      path: '/bookings/scheduled',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countTotalBooking.startVal,"endVal":_vm.countTotalBooking.endVal,"duration":_vm.countTotalBooking.duration}})],1)],1),_vm._m(3)])])]),_c('div',{staticClass:"card-col col-xl-4 col-lg-4 col-md-4 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 icon-lg"},[_c('i',{staticClass:"mdi mdi-ticket-confirmation"}),_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8696"},attrs:{"to":{
                      path: '/bookings/scheduled',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countTodayCompletedBooking.startVal,"endVal":_vm.countTodayCompletedBooking.endVal,"duration":_vm.countTodayCompletedBooking.duration}})],1)],1),_vm._m(4)])])]),_c('div',{staticClass:"card-col col-xl-4 col-lg-4 col-md-4 col-6"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column flex-sm-row"},[_c('div',{staticClass:"text-primary mr-0 icon-lg"},[_c('i',{staticClass:"mdi mdi-ticket-confirmation"}),_c('router-link',{staticStyle:{"text-decoration":"none","color":"#3a8696"},attrs:{"to":{
                      path: '/bookings/scheduled',
                    }}},[_c('countTo',{attrs:{"startVal":_vm.countScheduledBooking.startVal,"endVal":_vm.countScheduledBooking.endVal,"duration":_vm.countScheduledBooking.duration}})],1)],1),_vm._m(5)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Customers")]),_c('div',{staticClass:"fluid-container"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Drivers")]),_c('div',{staticClass:"fluid-container"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Help & Support")]),_c('div',{staticClass:"fluid-container"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper text-center text-sm-right"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Total Ticket Booking")]),_c('div',{staticClass:"fluid-container"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Today Completed Booking")]),_c('div',{staticClass:"fluid-container"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper text-center text-sm-left"},[_c('p',{staticClass:"card-text mb-0"},[_vm._v("Today Scheduled Booking")]),_c('div',{staticClass:"fluid-container"})])
}]

export { render, staticRenderFns }