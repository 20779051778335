var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',[_c('b-skeleton',{attrs:{"animation":"wave","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"70%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"50%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"60%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"80%"}})],1)]},proxy:true}])},[_c('BarChartGenerator',{ref:"bookingChart",attrs:{"chartId":"Line-Booking-Chart","chart-data":_vm.chartData,"chart-options":{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          title: { display: true, text: 'Total Booking Completed' },
        },
        responsive: true,
        maintainAspectRatio: false,
      }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }