var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LineChartGenerator',{ref:"walletChart",attrs:{"chartId":"Line-Wallet-Chart","chart-data":_vm.chartData,"chart-options":{
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: true, text: 'Customer Wallet ReCharge' },
    },
    responsive: true,
    maintainAspectRatio: false,
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }